body {
    text-align: center;
    margin: 0;
  }

  h1 {
    margin-top: 50px;
  }

  #counter {
    margin-top: 50px;
  }

  #count-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #count {
    font-size: 100px;
    margin: 0 20px;
  }

  button {
    margin-top: 20px;
    font-size: 24px;
    padding: 10px 20px;
  }

  #timestamps {
    padding-top: 2rem;
  }