.navbar {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
}
.navbarContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
}
.navbarLogo {
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    height: 100%;
}
.personIcon {
    color: #fff;
    justify-self: start;
    display: flex;
    cursor: pointer;
    text-decoration: none;
    position: absolute;
    top: 0;
    right: 0;
    /* margin-top: 1.5rem; */
    margin-right: 2.5rem;
    font-size: 2.2rem;    
}
.navbarItem {
    height: 80px;
    text-decoration: none;
    
}
/* .navLinks {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-size: 10rem;
} */
