.button-box {
    background-color: rgba(23, 26, 32, 65);
    height: 43px;
    width: 240px;
    border-radius: 4px;
}
.button-text {
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
    text-align: center;
}