.my-background {
    background-image: url("../../../assets/images/santiDrone1.jpg");
    background-size: cover;
    min-height: 92vh;
    background-position: 50% 0%;
}

.my-background-header {
    /* color: rgba(23, 26, 32, 1); */
    width: 197px;
    height: 58px;
    font-size: 48px;
    font-weight: bold;
    position: absolute;
    left: 100px;
    top: 125px;
    backdrop-filter: var();
}

.my-header {
    /* color: rgba(23, 26, 32, 1); */
    font-size: 48px;
    font-weight: bold;
    padding-top: 20px;
}

.my-section {
    height: 92vh;
}