/* body {
  margin: 0;
} */

body {
    margin: 0;
    background-color: white;
    color: black;
  }
  
  body.dark-mode {
    background-color: black;
    color: white;
  }
    
  
    /* main-container */
    .main-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
    }
    .main-left {
      width: 50%;
    }
    .main-left p {
      font-size: 18px;
    }
    .main-right {
      width: 50%;
    }
    /* .main-right img { */
    .myHeadStyle {
      max-width: 100%;
      height: auto;
    }
  
    .main-caption {
      margin-top: 0;
      font-size: 14px;
      text-align: center;
      padding: .5rem;
      background-color: #c0c0c2;
      color:black;
    }
  
    .main-bottom {
      width: 100%;
      margin-top: 20px;
      margin-left: 40px;
    }
    
    .main-bottom h2 {
      font-size: 20px;
      margin-bottom: 10px;
    }
    
    .main-bottom ul {
      list-style-type: disc;
      margin-left: 20px;
    }
  
    .main-bottom li {
      margin-bottom: 10px;
    }
    
  
  
  
    /* .nav-links {
      color: '#fff';
      display: 'flex';
      align-items: 'center';
      text-decoration: 'none';
      padding: '0.5rem 1rem';
      height: '100%';
    } */
    
    /* .navbar-logo {
      color: '#fff';
      justify-self: 'start';
      cursor: 'pointer';
      text-decoration: 'none';
      font-size: '2rem';
      display: 'flex';
      align-items: 'center';
      height: '100%';
    } */

    /* CHAT Dark */
    /* body {
      background-color: #fff;
      color: #000;
    }
     */
    .dark-mode body {
      background-color: #000;
      color: #fff;
    }
    