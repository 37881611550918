.form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .form-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-container h1{
    font-size: 50px;
  }

  .form-container label {
    font-size: 25px;
  }
  
  input[type="email"], textarea {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
    max-width: 300px;
  }
  
  .feedback textarea {
    height: 100px;
  }

  /* RATING BEGIN */
  .rating {
    display: flex;
    flex-direction: row-reverse;
  }
  
  .rating input {
    display: none;
  }
  
  .rating label {
    font-size: 30px;
    color: lightgray;
    cursor: pointer;
  }
  
  .rating label:hover {
    color: gray;
  }
  
  .rating input:checked ~ label {
    color: gold;
  }
  
/* RATING END */
  
  .form-container button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 20px;
    cursor: pointer;
  }

  .form-container button:hover {
    background-color: #0071ea;
    box-shadow: 0 0 10px 0 hsl(0, 30%, 1%);
    transition: ease-in 0.3s;
  }
  
  #error-message {
    color: red;
    margin-top: 5px;
  }