.listItem {
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
  /* padding: 16px; */
  margin-top: 8px;
  margin-bottom: 8px;
  text-transform: capitalize;
}

.listItemComplete {
  display: flex;
  justify-content: space-between;
  background-color: lightslategray;
  /* padding: 16px; */
  margin-top: 8px;
  margin-bottom: 8px;
  text-transform: capitalize;
  text-decoration: line-through;
}

.row {
  /* display: flex; */
}

.text {
  margin-left: 8px;
  cursor: pointer;
}

.textComplete {
  margin-left: 8px;
  cursor: pointer;
  text-decoration: line-through;
}

.button {
  cursor: pointer;
  display: flex;
  align-items: center;
}
