.button-group {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 25px;
  }
  
  .my-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    font-size: 2rem;
    transition: all 0.2s ease-in-out;
  }
  
  .my-button:hover {
    background-color: #0069d9;
  }
  
  .my-link {
    background: black;
  }