.bg {
    height: 100vh;
  }
  
  .container {
    background-color: slategray;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    padding: 4px;
    border-radius: 0.5rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15);
  }
  
  .heading {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
  }
  
  .form {
    display: flex;
    justify-content: space-between;
  }
  
  .input {
    border: 1px solid;
    /* padding: 8px; */
    /* width: 100%; */
    font-size: 1.25rem;
  }
  
  .button {
    border: 1px solid;
    padding: 16px;
    /* margin-left: 8px; */
    /* background-color: #007bff; */
    /* color: slategray; */
    color : black;
  }
  
  .count {
    text-align: center;
  }
  